<template>
  <v-container fluid>
      <v-row align="center" justify="center">
          <v-col cols="12">
              <v-img max-height="40vh"  contain src="../../../assets/no_tasks.svg"></v-img>
          </v-col>
          <v-col cols="12">
              <v-container>
                  <v-row>
                      <v-spacer></v-spacer>
                      <p class="normal-text primary--text text-center">{{$tr("Your Bundle Has Been Accepted")}}</p>
                      <v-spacer></v-spacer>
                  </v-row>
              </v-container>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>